<template>
  <div class="add-vehicle">
    <div class="container-fluid px-xl-4 py-4"> <confirm-change-menu @close="conformDailog = false"
        :dialog="conformDailog" @confirm="goToNext()" />
      <div class="add-form custom-card">
        <form-wizard class="wizard-tab-content" shape="square" color="#18541d" @on-complete="save">
          <tab-content title="Individual parts" :before-change="validateForm">
            <b-form>
              <b-row>
                <b-col class="col-sm-12">
                  <h3 class="mb-3" v-if="$route.name === 'Add Vehicle Part'">
                    Add part to inventory From the following vehicle
                  </h3>
                  <h3 class="mb-3" v-if="
                    $route.name === 'Update Vehicle Part' ||
                    ($route.name === 'Edit Part' && data.profile_type === 'auction')
                  ">
                    Update part to inventory From the following vehicle
                  </h3>
                  <h3 class="mb-3" v-if="$route.name === 'Add Parts'">Add Direct Parts <span
                      class="label label-full-danger">Note: Chevrolet, Cadillac, Buick are now GENERAL MOTORS</span>
                  </h3>
                  <h3 v-if="$route.name === 'Edit Part' && data.profile_type === 'direct'">
                    Update Direct Parts
                  </h3>
                </b-col>

                <b-col class="col-sm-12">
                  <div><span id="msg"></span></div>
                  <div class="upload-images-wrapper form-group">
                    <label class="d-block">Part Images</label>
                    <b-form-group id="input-group-01" label-for="input-01">
                      <image-uploaders id="fileInputFront" :quality="0.9" outputFormat="verbose" :preview=false
                        :className="['hidden']" @input="onFileChange($event)" @onUpload="onUpload($event)" ref="front"
                        :multiple="true" :maximum="imageTags.length - image.length">
                        <label for="fileInputFront" slot="upload-label" class="node-image-uploader">
                          <svg data-v-74177b85="" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path data-v-74177b85=""
                              d="M12 20H5C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V9C3 8.46957 3.21071 7.96086 3.58579 7.58579C3.96086 7.21071 4.46957 7 5 7H6C6.53043 7 7.03914 6.78929 7.41421 6.41421C7.78929 6.03914 8 5.53043 8 5C8 4.73478 8.10536 4.48043 8.29289 4.29289C8.48043 4.10536 8.73478 4 9 4H15C15.2652 4 15.5196 4.10536 15.7071 4.29289C15.8946 4.48043 16 4.73478 16 5C16 5.53043 16.2107 6.03914 16.5858 6.41421C16.9609 6.78929 17.4696 7 18 7H19C19.5304 7 20.0391 7.21071 20.4142 7.58579C20.7893 7.96086 21 8.46957 21 9V12.5M16 19H22M19 16V22"
                              stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path data-v-74177b85=""
                              d="M9 13C9 13.7956 9.31607 14.5587 9.87868 15.1213C10.4413 15.6839 11.2044 16 12 16C12.7956 16 13.5587 15.6839 14.1213 15.1213C14.6839 14.5587 15 13.7956 15 13C15 12.2044 14.6839 11.4413 14.1213 10.8787C13.5587 10.3161 12.7956 10 12 10C11.2044 10 10.4413 10.3161 9.87868 10.8787C9.31607 11.4413 9 12.2044 9 13Z"
                              stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg><span data-v-74177b85="" class="upload-caption">Click to upload</span></label>
                      </image-uploaders>
                    </b-form-group>
                    <div class="d-flex flex-wrap gap-10">
                      <span class="label label-danger">Up to 4 images only</span>
                      <span class="errMsg">{{ $t('partsList.partImageNote') }}</span>
                    </div>

                    <div class="img-preview">
                      <div class="img-loader d-none">
                        <div class="vld-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" stroke="#4CAF50">
                            <g fill="none" fill-rule="evenodd">
                              <g transform="translate(1 1)" stroke-width="2">
                                <circle stroke-opacity=".25" cx="18" cy="18" r="18"></circle>
                                <path d="M36 18c0-9.94-8.06-18-18-18">
                                  <animateTransform attributeName="transform" type="rotate" from="0 18 18"
                                    to="360 18 18" dur="0.8s" repeatCount="indefinite"></animateTransform>
                                </path>
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <div class="img-pre-box" v-for="i of this.image">

                        <div :id="i.tag" class="select-image select-image-div">
                          <img :class="i.tag" :src="i.url100" alt=''>
                        </div>
                        <a :id="i.tag + '-delicon'" class="delicon" data-tooltip="Delete" @click="deleteImage(i.tag)">
                          <svg data-v-74177b85="" width="7" height="7" viewBox="0 0 7 7" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path data-v-74177b85="" d="M1.12136 1.12132L5.364 5.36396M5.364 1.12132L1.12136 5.36396"
                              stroke="red" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <h3 data-v-74177b85="" class="mt-4 mb-3">Part Details</h3>
              <div class="c-vehicle-vin-wrap">
                <b-row v-if="$route.name === 'Add Parts'">
                  <b-col class="col-lg-4 col-md-6 col-sm-12">
                    <b-form-group id="input-group-13" label="Quantity" label-for="input-13">
                      <b-form-input id="input-13" type="number" placeholder="Quantity" v-model="data.quantity"
                        @input="$v.data.quantity.$touch()" @blur="$v.data.quantity.$touch()" :class="this.$v.data.quantity.$dirty && $v.data.quantity.$invalid
                          ? 'error-message'
                          : ''
                          "></b-form-input>
                      <span class="errMsg"> {{ quantityErrors[0] }}</span>
                    </b-form-group>

                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="col-lg-4 col-md-6 col-sm-12" v-if="
                    $route.name === 'Add Parts' ||
                    ($route.name === 'Edit Part' &&
                      data.profile_type === 'direct')
                  ">
                    <b-form-group id="input-group-2" label="Year" label-for="input-2">
                      <v-select :options="yearList" id="input-2" class="form-dropdown" type="text" placeholder="Year"
                        v-model="data.year" @input="
                          changeYearOption();
                        $v.data.year.$touch();
                        " @blur="$v.data.year.$touch()" required :class="this.$v.data.year.$dirty && $v.data.year.$invalid
                          ? 'error-message'
                          : ''
                          ">
                      </v-select>
                      <span class="errMsg"> {{ yearErrors[0] }}</span>
                    </b-form-group>

                  </b-col>

                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-3" label="Make" label-for="input-3">
                      <v-select :disabled="!data.year" id="input-3" :options="makeList" class="form-dropdown"
                        placeholder="Make" v-model="data.make" item-text="label" item-value="MfrName" @input="
                          $v.data.make.$touch();
                        changeMakeOption();
                        " @blur="$v.data.make.$touch()" required :class="(!data.make && data.year && data.vin) || (this.$v.data.make.$dirty && $v.data.make.$invalid)
                          ? 'error-message'
                          : ''
                          "></v-select>
                      <span class="errMsg"> {{ makeErrors[0] }}</span>
                    </b-form-group>

                  </b-col>

                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-3" label="Model" label-for="input-3">
                      <v-select :disabled="!data.make" id="input-4" :options="modelList" class="form-dropdown"
                        placeholder="Model" v-model="data.model" item-text="label" item-value="ModelNm" @input="
                          $v.data.model.$touch();
                        changeModelOption();
                        " @blur="$v.data.model.$touch()" required :class="((!data.model && data.make && data.year && data.vin) || (this.$v.data.model.$dirty && $v.data.model.$invalid))
                          ? 'error-message'
                          : ''
                          "></v-select>
                      <span class="errMsg"> {{ modelErrors[0] }}</span>
                    </b-form-group>

                  </b-col>

                  <b-col class="col-lg-4 col-md-6 col-sm-12">
                    <b-form-group id="input-group-6" label="Part Name" label-for="input-6">
                      <v-select :disabled="!data.model" id="input-4" :options="partsOption" class="form-dropdown"
                        placeholder="Part Name" v-model="data.part_name" item-text="text" item-value="value"
                        @input="onChangePartName(); $v.data.part_name.$touch();" required :class="(!data.part_name && data.year && data.vin) || (this.$v.data.part_name.$dirty && $v.data.model.$invalid)
                          ? 'error-message'
                          : ''
                          "></v-select>
                      <span class="errMsg"> {{ partNameErrors[0] }}</span>
                    </b-form-group>

                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <b-form-group id="input-group-8" label="Interchange" label-for="input-8">
                      <b-form-select id="input-8" type="text" class="form-control" placeholder="Part Grade"
                        v-model="data.interchange"
                        :disabled="(!data.part_name || (data.part_name && interchangeList.length == 0))"
                        @change="onChangeInterchange($event)"
                        :class="(!data.interchange && isInterchangeRequired) ? 'error-message' : ''">
                        <option :value="null" v-if="interchangeList.length == 0">{{
                          $t("partDialog.select_no_interchange_found") }}</option>
                        <option :value="null" v-if="interchangeList.length > 0">{{ $t("partDialog.select_interchange")
                          }}</option>
                        <template v-for="(interchange, index) in interchangeList">
                          <template>
                            <option :v-if="interchange.IntchNbr"
                              v-bind:class="[interchange.IntchNbr ? 'font-weight-bold mb-3' : '']" :key="index + 1"
                              :value="interchange.InterchangeNumber" :disabled="!interchange.IntchNbr">
                              {{ interchange.Application }}&nbsp;&nbsp;&nbsp;&nbsp;
                              {{ interchange.InterchangeNumber ? '(' + interchange.InterchangeNumber + ')' : '' }}
                            </option>
                          </template>
                        </template>
                      </b-form-select>
                      <span class="errMsg" v-if="isInterchangeRequired && !data.interchange">{{
                        $t("validations.interchangeIsRequired")
                      }}</span>
                    </b-form-group>

                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12">
                    <b-form-group id="input-group-5" label="Miles" label-for="input-5">
                      <b-form-input :disabled="!data.part_name" id="input-5" type="number" placeholder="Miles"
                        v-model="data.miles" @input="$v.data.miles.$touch()" @blur="$v.data.miles.$touch()" required
                        :class="this.$v.data.miles.$dirty && $v.data.miles.$invalid
                          ? 'error-message'
                          : ''
                          "></b-form-input>
                      <span class="errMsg"> {{ milesErrors[0] }}</span>
                    </b-form-group>

                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12">
                    <b-form-group id="input-group-7" :label="$t('partsList.recommendedSales$')" label-for="input-7">
                      <b-input-group>
                        <b-input-group-prepend>
                          <span class="input-group-text">$</span>
                        </b-input-group-prepend>
                        <b-form-input :disabled="!data.part_name" id="input-7" type="number"
                          placeholder="Recommended Sales $" v-model="data.recommended_saleprice"
                          @keypress="validCost($event)">
                          <!-- @input="
                        $v.data.recommended_saleprice.$touch();
                      "
                      @blur="$v.data.recommended_saleprice.$touch()" -->
                        </b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12">
                    <b-form-group id="input-group-8" label="Part Grade" label-for="input-8">
                      <b-form-select :disabled="!data.part_name" id="input-8" type="text" class="form-control"
                        :options="gradeOptions" placeholder="Part Grade" v-model="data.part_grade"></b-form-select>
                      <!-- @input="$v.data.part_grade.$touch()"
                    @blur="$v.data.part_grade.$touch()" -->
                    </b-form-group>
                  </b-col>
                  <b-col class="col-lg-4 col-md-6 col-sm-12">
                    <b-form-group id="input-group-10" label="Warranty" label-for="input-10">
                      <b-form-select :disabled="!data.part_name" id="input-10" type="number" class="form-control"
                        :options="warrantyOptions" v-model="data.warranty_days" placeholder="Warranty"></b-form-select>
                    </b-form-group>
                  </b-col>

                </b-row>
              </div>
              <h3 data-v-74177b85="" class="mt-4 mb-3">Location & Notes</h3>
              <b-row>
                <b-col class="col-lg-12 col-md-6 col-sm-12">
                  <b-form-group id="input-group-10" label-for="input-10">
                    <b-form-textarea id="textarea" placeholder="Enter something..." rows="3" v-model="data.description"
                      @input="$v.data.description.$touch()" @blur="$v.data.description.$touch()" required :class="this.$v.data.description.$dirty &&
                        $v.data.description.$invalid
                        ? 'error-message'
                        : ''
                        " max-rows="6"></b-form-textarea>
                    <span class="errMsg"> {{ descriptionErrors[0] }}</span>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </tab-content>
                    
          <div class="wizard-card-footer clearfix custom-wizard">
            <div class="wizard-footer-left"><!----> </div>
            <div class="wizard-footer-right">
              <span role="button" tabindex="0">
                <wizard-button tabindex="-1" @click.native="save" type="button" class="btn custom-btn pattern-btn">
                  <span>Submit</span>
                </wizard-button>
              </span>
            </div>
          </div>
        </form-wizard>
      </div>
    </div>
  </div>
</template>
<script>
import { debounce } from "lodash";
import {
  required,
  between,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
import { showSuccess, showFailure } from "../eventbus/action.js";
import { mapActions, mapGetters } from "vuex";
import $ from "jquery";
import ConfirmChangeMenu from "../components/ConfirmChangeMenu";
import ImageUploaders from "../components/ImageUploaders.vue";

export default {
  components: {
    ConfirmChangeMenu,
    ImageUploaders
  },
  data() {
    return {
      hasImage: false,
      image: [],
      initialize: true,
      partsOption: [],
      data: {
        interchange: null,
        make: null,
        model: null,
        year: null,
        miles: null,
        part_name: null,
        part_grade: null,
        quantity: 1,
        description: null,
        recommended_saleprice: "",
        warranty_days: null,
        select_interchange_text: "",
      },

      item: {
        value: "",
        text: "",
      },
      vinLoading: false,
      gradeOptions: [
        { text: "Select", value: null },
        { text: "A", value: "A" },
        { text: "B", value: "B" },
        { text: "C", value: "C" },
      ],
      warrantyOptions: [
        { text: "Select", value: null },
        { text: "AS IS", value: "AS IS" },
        { text: "5 days", value: "5 days" },
        { text: "15 days", value: "15 days" },
        { text: "30 days", value: "30 days" },
        { text: "60 days", value: "60 days" },
        { text: "90 days", value: "90 days" },
      ],
      quantityList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      selected: [], // Must be an array reference!
      options: [
        //{ text: "Facebook", value: "is_on_facebook" },
        //{ text: "Instagram", value: "is_on_instagram" },
        //{ text: "Ebay", value: "is_on_ebay" },
      ],
      makeList: [],
      modelList: [],
      yearList: [],
      pTypeList: [],
      trimList: [],
      inputs: [
        {
          file: "",
        },
      ],
      files_url: "",
      count: 1,
      conformDailog: false,
      isInterchangeRequired: false,
      imageTags: ['img1', 'img2', 'img3', 'img4'],
      isDataSaved: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.query && to.query.isRedirect != true) {
      this.conformDailogOpen(next);
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      yearOptions: "interchange/getYears",
      makeOptions: "interchange/getMake",
      modelOptions: "interchange/getModel",
      partsList: "partlist/getPartNames",
      carDetailData: "carlist/getCarDetail",
      // imageResponse: "partlist/getImageResponse",
      mediaImageResponse: "partlist/getMediaImageResponse",
      vinSearch: "partlist/getVinSearchData",
      partsData: "partlist/getPartById",
      trimOptions: "partlist/getTrim",
      interchangeList: "partlist/getInterchange",
      // pType: "partlist/getPType",
      partsSettings: "partlist/getPartsSettings",
    }),
    milesErrors() {
      const errors = [];
      if (!this.$v.data.miles.$dirty) return errors;
      !this.$v.data.miles.between &&
        errors.push(this.$t("validations.milesIsBetween"));
      return errors;
    },
    makeErrors() {
      const errors = [];
      if (!this.$v.data.make.$dirty) return errors;
      !this.$v.data.make.required &&
        errors.push(this.$t("validations.makeIsRequired"));
      return errors;
    },
    modelErrors() {
      const errors = [];
      if (!this.$v.data.model.$dirty) return errors;
      !this.$v.data.model.required &&
        errors.push(this.$t("validations.modelIsRequired"));
      return errors;
    },
    yearErrors() {
      const errors = [];
      if (!this.$v.data.year.$dirty) return errors;
      !this.$v.data.year.required &&
        errors.push(this.$t("validations.yearIsRequired"));
      return errors;
    },
    partNameErrors() {
      const errors = [];
      if (!this.$v.data.part_name.$dirty) return errors;
      !this.$v.data.part_name.required &&
        errors.push(this.$t("validations.partNameIsRequired"));
      return errors;
    },
    RSPErrors() {
      const errors = [];
      if (!this.$v.data.recommended_saleprice.$dirty) return errors;
      !this.$v.data.recommended_saleprice.required &&
        errors.push(this.$t("validations.RSPIsRequired"));
      return errors;
    },
    partGradeErrors() {
      const errors = [];
      if (!this.$v.data.part_grade.$dirty) return errors;
      !this.$v.data.part_grade.required &&
        errors.push(this.$t("validations.partGradeIsRequired"));
      return errors;
    },
    quantityErrors() {
      const errors = [];
      if (!this.$v.data.quantity.$dirty) return errors;
      !this.$v.data.quantity.between &&
        errors.push(this.$t("validations.quantityIsBetween"));
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.data.description.$dirty) return errors;
      !this.$v.data.description.maxLength &&
        errors.push(this.$t("validations.desMaxLength"));
      return errors;
    },
  },
  watch: {},
  validations: {
    data: {
      year: {
        required: requiredIf(function () {
          return (
            this.$route.name === "Add Parts" ||
            (this.$route.name === "Edit Part" &&
              this.data.profile_type === "direct")
          );
        }),
      },
      make: { required },
      model: { required },
      miles: {
        between: between(1, 1000000000),
      },
      part_name: { required },
      quantity: { between: between(1, 1000000000) },
      description: { maxLength: maxLength(150) },
    },
  },
  destroyed() {
    $("li.parts-wrapper > a").removeClass(
      "router-link-exact-active router-link-active"
    );
  },
  async mounted() {
    await this.handleDelIcon();
    this.initialize = true;
    await this.setYear();
    await this.setMake();
    await this.setPType();
    setTimeout(() => {
      $("li.parts-wrapper > a").addClass(
        "router-link-exact-active router-link-active"
      );
    }, 10);
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (this.$route.params.uid) {
      await this.fetchVehiclePartInfo(Number(this.$route.params.uid));
    }

    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },

  updated() {
    if (this.interchangeList.length < 1) {
      this.data.select_interchange_text = this.$t("partDialog.select_no_interchange_found");
      this.data.interchange = null;
    } else {
      this.data.select_interchange_text = this.$t("partDialog.select_interchange");
    }
  },

  methods: {
    ...mapActions({
      getYears: "interchange/getYears",
      getMake: "interchange/getMake",
      getModel: "interchange/getModel",
      // uploadImage: "partlist/uploadImage",
      uploadMediaImage: "partlist/uploadMediaImage",
      addParts: "partlist/addParts",
      getVinSearch: "partlist/vinSearch",
      partsById: "partlist/getPartsById",
      carDetail: "carlist/getCarDetail",
      updateVehicleParts: "partlist/updateVehicleParts",
      addDirectParts: "partlist/addDirectParts",
      getPartName: "partlist/getPartNames",
      getTrim: "partlist/getTrim",
      getInterchange: "partlist/getInterchange",
      // getPType: "partlist/getPType",
      getPartsSetting: "partlist/getPartsSetting",
    }),

    handleBeforeUnload(event) {
      if (!this.isDataSaved) {
        const confirmationMessage = 'You have unsaved changes. Do you really want to leave?';
        event.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    },

    filterParts: debounce(function () {
      this.getCarNameDetails();
    }, 1500),

    async onChangePartName() {
      try {
        this.$store.commit("setLoader", true);
        this.interchange = null;
        this.miles = null;
        this.part_name = null;
        this.part_grade = null;
        this.recommended_saleprice = "";
        this.warranty_days = null;
        if (this.data.make && this.data.model && this.data.part_name) {
          await this.getInterchange({
            BeginYear: this.data.year,
            MfrCd: this.data.make.MfrCd,
            ModelNm: this.data.model.ModelNm,
            PartType: this.data.part_name.value
          });
        }
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object"
            ? Object.values(message)[0][0]
            : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    validateForm() {
      this.$v.data.$touch();
      if (!this.$v.data.$invalid) {
        return true;
      } else {
        return false;
      }
    },

    onChangeInterchange(newInterchange) {
      if (this.interchangeList && this.interchangeList.length != 0) {
        this.isInterchangeRequired = true;
        // this.data.interchange = newInterchange;
      } else {
        this.isInterchangeRequired = false;
      }
    },

    handleDelIcon() {
      for (let i = 1; i <= 8; i++) {
        $(`#img${i}-delIcon`).hide();
      }
    },

    async deleteImage(tag) {
      this.image = await this.image.filter((item) => item.tag !== tag);

      // this.$refs[tag].$el.children[1].value = "";
      // $(`#${tag}`).empty();
      // $(`#${tag}-delIcon`).hide();
      // delete this.image[tag];
    },

    async setPType() {
      try {
        // await this.getPType();
        await this.getPartsSetting();

        this.partsOption = this.partsSettings.map(function (val) {
          return {
            value: val.PartType,
            label: val.Description,
          };
        });

      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },

    async changeMakeOption() {
      this.data.model = null;
      this.data.part_name = null;
      await this.setModel();
    },

    async changeModelOption() {
      this.data.part_name = null;
    },

    async setYear() {
      this.yearList = [];
      try {
        await this.getYears();
        this.yearList = await [...this.yearOptions];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },

    async setMake() {
      this.makeList = [];
      try {
        this.$store.commit("setLoader", true);
        await this.getMake({});
        this.makeList = await [...this.makeOptions];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async setModel() {
      this.modelList = [];
      try {
        this.$store.commit("setLoader", true);
        await this.getModel({
          MfrCd: this.data.make.MfrCd,
        });
        this.modelList = await [...this.modelOptions];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async changeYearOption() {
      this.data.make = null;
      this.data.model = null;
      this.data.interchange = null;
      this.data.miles = null;
      this.data.part_name = null;
      this.data.part_grade = null;
      this.data.recommended_saleprice = "";
      this.data.warranty_days = null;
    },

    validCost(e) {
      const validate = this.data.recommended_saleprice.split(".");
      if (validate[1] && validate[1].length >= 2) {
        e.preventDefault();
      }
      const reg = /[^0-9]/;
      if (reg.test(e.key)) {
        e.preventDefault();
      }
    },

    convertUSCurrency() {
      let currency = 0;

      if (this.data.recommended_saleprice) {
        currency = Number(
          this.data.recommended_saleprice.toString().replace(/[^0-9/.]/g, "")
        )
          .toString()
          .split(".");

        if (!currency[1]) {
          this.data.recommended_saleprice = currency[0]
            .replace(/\D/g, "")
            .split("")
            .reverse()
            .reduce(function (acc, num, i) {
              return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
            }, "");
        } else {
          this.data.recommended_saleprice =
            currency[0]
              .replace(/\D/g, "")
              .split("")
              .reverse()
              .reduce(function (acc, num, i) {
                return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
              }, "") +
            "." +
            currency[1];
        }
      }
    },

    async fetchVehiclePartInfo(partid) {
      try {
        this.$store.commit("setLoader", true);
        await this.partsById(partid);

        this.data = Object.assign({}, this.partsData);

        await this.getInterchange({
          BeginYear: this.data.year,
          MfrCd: this.data.make_code,
          ModelNm: this.data.model_code,
          PartType: this.data.part_id
        });

        const carlineMake = this.partsData.make;
        let make = null;
        const carlineModel = this.partsData.model;
        let model = null;

        this.makeList.map(function (element) {
          if (element.MfrName === carlineMake) {
            make = element;
          }
        });

        if (make) {
          this.data.make = make;

          await this.setModel();
          await this.modelList.map(function (element) {
            if (element.ModelNm === carlineModel) {
              model = element;
            }
          });

          if (model) {
            this.data.model = model;
          }
        }

        let part_name = this.data.part_name;
        this.partsOption.map(function (element) {
          if (element.label === part_name) {
            part_name = element;
          }
        })

        this.data.part_name = part_name;

        this.data.interchange = this.data.interchange_code;

        if (this.data.year && this.data.make && this.data.model && this.data.part_name) {
          await this.getInterchange({
            BeginYear: this.data.year,
            MfrCd: this.data.make.MfrCd,
            ModelNm: this.data.model.ModelNm,
            PartType: this.data.part_name.value
          });
        }
        // this.data.interchange = this.partsData.interchange_code;

        if (this.partsData.is_on_ebay) {
          this.selected.push("is_on_ebay");
        }
        if (this.partsData.is_on_facebook) {
          this.selected.push("is_on_facebook");
        }
        if (this.partsData.is_on_instagram) {
          this.selected.push("is_on_instagram");
        }
        //this.convertUSCurrency();
        this.image = await this.partsData.medias;
        // this.partsData.medias.forEach((val) => {
        //   $(`#${val.tag}`).append(`<img class=${val.tag} src='' alt=''>`);
        //   this.image[val.tag] = {
        //     data: val,
        //   };
        //   $(`.${val.tag}`).attr("src", val.url100);
        //   $(`#${val.tag}-delIcon`).show();
        // });
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async saveImage(file, tag) {
      try {
        // this.$store.commit("setLoader", true);
        $(".img-loader").removeClass('d-none');
        $(".wizard-btn").prop('disabled', true);
        let formData = new FormData();
        await formData.append("file", file);
        await formData.append("tag", tag);
        await formData.append("company_name", this.user.user_detail.company_name);
        // await this.uploadImage(formData);
        await this.uploadMediaImage(formData);
        // showSuccess("Image Saved Successfully");
        // return this.imageResponse;
        return this.mediaImageResponse;
        // this.data.media_ids = this.filelist.map((val) => val.data.id);
      } catch ({ message }) {
        // this.$refs[tag].$el.children[1].value = "";
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        // this.$store.commit("setLoader", false);
        $(".img-loader").addClass('d-none');
        $(".wizard-btn").prop('disabled', false);
      }
    },

    async getCarDetails() {
      try {
        this.$store.commit("setLoader", true);
        await this.carDetail(this.$route.params.vid);
        this.data.miles = this.carDetailData.miles;
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async getCarNameDetails() {
      try {
        const val = $("#input-group-6 input").val();
        this.$store.commit("setLoader", true);
        await this.getPartName({ search: val });
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async getPartDetails() {
      try {
        this.$store.commit("setLoader", true);
        await this.partDetails(this.$route.params.id);
      } catch ({ message }) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
    async onUpload(fileData) {
      if (!fileData.status) {
        showFailure(fileData.message);
      }
    },
    async onFileChange(fileData) {
      var type = null;
      var selectedTags = this.image.map(function (i) {
        return i.tag;
      });
      if (selectedTags.length != this.imageTags.length) {
        let difference = this.imageTags.filter(x => !selectedTags.includes(x));
        if (difference.length >= fileData.info.index + 1) {
          type = difference[fileData.info.index];
        } else {
          return;
        }

        var selectedImage = await this.saveImage(fileData.dataUrl, type);
        this.image = await this.image.filter((item) => item.tag !== type);
        await this.image.push(selectedImage.data);
      } else {
        var allowedImage = this.imageTags.length - selectedTags.length;
        if (allowedImage == 0) {
          showFailure(`You already selected all ${this.imageTags.length} images.`);
        }
        return;
      }

      // $(`#${type}`).empty();
      // $(`#${type}`).append(`<img class=${type} src='' alt=''>`);
      // this.image[type] = await this.saveImage(fileData.dataUrl, type);
      // $(`.${type}`).attr("src", this.image[type].data.url100);
      // $(`#${type}-delIcon`).show();
    },

    changeRouter() {
      if (this.$route.name === "Add Vehicle Part") {
        this.$router.push({ name: "Car list", query: { isRedirect: true } });
      } else if (this.$route.name === "Update Vehicle Part") {
        this.$router.push({
          name: "Car Detail",
          params: { id: this.partsData.car_profile_id },
          query: { isRedirect: true }
        });
      } else if (
        this.$route.name === "Edit Part" ||
        this.$route.name === "Add Parts"
      ) {
        this.$router.push({ name: "Part list", query: { isRedirect: true } });
      }
    },

    async save() {
      this.onChangeInterchange();
      this.$v.data.$touch();
      if (!this.$v.data.$invalid && ((this.isInterchangeRequired == true && typeof this.data.interchange == 'string') || this.isInterchangeRequired == false)) {
        try {
          this.$store.commit("setLoader", true);
          let payload = Object.assign({}, this.data);
          payload.car_profile_id = null;

          payload.make = this.data.make.MfrName;
          payload.make_code = this.data.make.MfrCd;
          payload.model = this.data.model.ModelNm;
          payload.model_code = this.data.model.ModelNm;

          payload.part_name = this.data.part_name.label;
          payload.part_number = this.data.part_name.value;

          payload.part_id = this.data.part_name.value;
          payload.interchange_code = this.data.interchange;
          payload.media_ids = this.image.map((val) => val.id);
          payload.media_tags = this.image.map((val) => val.tag);
          if (this.$route.params.uid) {
            payload.car_profile_id = this.partsData.car_profile_id;
            await this.updateVehicleParts({
              ...payload,
            });
            showSuccess("Part Updated Successfully");
          }
          if (this.$route.params.vid) {
            await this.addParts({
              ...payload,
            });
            showSuccess("Part Added Successfully");
          }
          if (this.$route.name === "Add Parts") {
            if (payload.quantity && payload.quantity > 0) {
              await this.addDirectParts({
                ...payload,
              });
              showSuccess("Part Added Successfully");
            } else {
              showFailure("Please enter quantity, It should be an number greater than 0");
              return false;
            }
          }
          this.changeRouter();
        } catch ({ message }) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showFailure(errorMessage);
        } finally {
          this.$store.commit("setLoader", false);
        }
      }
    },
    conformDailogOpen(next) {
      this.conformDailog = true;
      this.link = next;
    },
    goToNext() {
      this.conformDailog = false;
      this.link();
    }
  },
};
</script>
<style scoped>
#msg {
  color: brown;
}

.select-image-div {
  max-width: 100px;
  max-height: 100px;
  min-height: 100px;
  min-width: 100px;
  display: flex;
  overflow: hidden;
}
</style>
